import React from 'react'
import Navbar from '../components/Navbar'
import lotus from "../assets/lotus.svg"
import LOGO from "../assets/LOGO.svg"
import intersect from "../assets/intersect.svg";
import elIpsum from "../assets/el-ipsum.svg"
import drops from "../assets/drops.svg"
import dashboard from "../assets/Dashboard.png"
import photo from "../assets/photo.png"
import { Icon } from '@iconify/react';
import { ArrowLeft, ArrowRight, ArrowRight2, Profile2User, TickCircle } from 'iconsax-react';
import files from '../assets/files.svg'
import lArrow from "../assets/leftarrow.svg"
import rArrow from "../assets/rightarrow.svg"
import blueLogo from "../assets/blueLogo.svg"
import logocrest from "../assets/logocrest.svg"
import atom from '../assets/bubbles/atom.svg'
import batt from '../assets/bubbles/batt.svg'
import battery from '../assets/bubbles/battery.svg'
import bug from '../assets/bubbles/bug.svg'
import dollar from '../assets/bubbles/dollar.svg'
import heart from '../assets/bubbles/heart.svg'
import message from '../assets/bubbles/message.svg'
import pointer from '../assets/bubbles/pointer.svg'
import pulse from '../assets/bubbles/pulse.svg'
import router from '../assets/bubbles/router.svg'
import signal from '../assets/bubbles/signal.svg'
import tick from '../assets/bubbles/tick.svg'
import { Link } from 'react-router-dom';
import head1 from '../assets/heads/head-1.svg'
import head2 from '../assets/heads/head-2.svg'
import head3 from '../assets/heads/head-3.svg'
import blue from '../assets/heads/blue.svg'
import testman from '../assets/heads/testman.svg'
import testman2 from '../assets/heads/testman2.svg'
import green from '../assets/heads/green.svg'
import yellow from '../assets/heads/yellow.svg'
import smallDash from '../assets/smalldash.png' 
import home from '../assets/Home.svg'
import menu from '../assets/menu.svg'
import fb from '../assets/fb.svg'
import twt from '../assets/twt.svg'
import linkedIn from '../assets/in.svg'
import dollarz from '../assets/dollarz.svg'
import logo from "../assets/logoblack.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useSwiper } from 'swiper/react';
 
const Home = () => {

  const swiper = useSwiper()

  return (
    <div className="w-full">
      {/* hero section */}
      <div className="w-full bg-blue-300 md:h-[120vh] xl:h-[180vh] 2xl:h-[140vh]  grad pb-[10rem]">
        <Navbar />

        <div className="container h-[90vh] md:h-[50vh] xl:h-[90vh] 2xl:h-[70vh] flex items-center justify-center flex-col  ">
          <h1 className=" md:text-[52px] xl:text-[84px] text-center font-bold font-DM text-white md:leading-[48px] xl:leading-[78px]  ">
            The best environment for <br /> Managing{" "}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
              Your Agency
            </span>
          </h1>
          <p className="text-3xl font-normal text-white font-DM md:mt-8 xl:mt-16">
            All in one solution. Unlimited Power.
          </p>

          <div className="flex items-center font-DM font-bold text-white gap-5 md:mt-7 xl:mt-14">
            <button className="bg-gradient-to-r from-primary to-accent py-3 px-5 shadow-lg hover:shadow-sm rounded-xl">
              Get Started
            </button>
            <button className="bg-eagray py-3 px-5  shadow-lg hover:shadow-sm rounded-xl">
              Book Demo
            </button>
          </div>
        </div>

        {/* trusted by */}
        <div className="container text-center">
          <h4 className="text-lfgray text-sm tracking-[2px] font-bold font-DM mb-14">
            {" "}
            TRUSTED BY
          </h4>
          <div className=" w-[90%] mx-auto flex items-center justify-between">
            <img src={lotus} alt="proflow.png" />
            <img src={LOGO} alt="proflow.png" />
            <img src={intersect} alt="proflow.png" />
            <img src={elIpsum} alt="proflow.png" />
            <img src={drops} alt="proflow.png" />
          </div>
        </div>
        {/* hero section ends here */}
      </div>

      {/* the dashboard picture section */}
      <div className="bg-white w-full h-[50vh] max-h-[450px] ">
        <div className="container h-full  relative">
          {/* dashboard reference */}
          <div className=" w-[84%] md:w-[70%] mx-auto h-full rounded-2xl absolute left-1/2 -translate-x-1/2 md:-top-[60%] xl:-top-[82%] 2xl:-top-[70%] ">
            <img className="w-full" src={dashboard} alt="proflow.png" />
          </div>

          {/* Message popup */}
          <div className=" w-52 flex items-center bg-white py-2 px-3 gap-2 rounded-md absolute left-0 md:left-10 xl:left-20 md:-top-[30%] xl:-top-[30%] 2xl:-top-[24%] shadow-lg  ">
            <div className="w-7 h-7 rounded-full ">
              <img className="w-full" src={photo} alt="proflow.png" />
            </div>
            <div className="flex flex-1 flex-col gap-1">
              <div className="flex items-center justify-between">
                <p className="font-DM text-[8px] font-bold text-black">
                  Jakob Saris
                </p>
                <span className="font-DM text-[8px] font-bold text-tgray">
                  2m Ago
                </span>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-DM text-[8px] font-bold text-tgray w-[88%] overflow-hidden whitespace-nowrap text-ellipsis ">
                  You: Sure! let me tell you about wha
                </p>
                <Icon className="text-xs text-primary" icon="mdi:tick-all" />
              </div>
            </div>
          </div>

          {/* Clients popup */}
          <div className=" flex flex-col items-center text-center bg-white py-2 px-4 gap-3 rounded-md absolute right-16 md:-top-40 xl:-top-[50%] 2xl:right-40 2xl:-top-[40%] shadow-lg">
            <Profile2User className="w-6 h-6 text-black rounded-md p-1 bg-lightGray border" />
            <div>
              <p className="text-[12px] text-deepGray leading-[8px] font-DM font-medium">
                Clients
              </p>
              <span className="text-[7px]  text-deepGray font-DM font-medium">
                Create and manage your clients section
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Proflow is the all in one solution */}
      <div className="container md:py-0 2xl:py-6 mx-auto text-center">
        <h2 className="font-DM font-bold md:text-6xl xl:text-7xl leading-[72px] md:mb-4 xl:mb-11 text-deepGray2">
          {" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
            ProFlow
          </span>{" "}
          is the all in <br /> one solution for you{" "}
        </h2>
        <p className="font-DM md:text-xl xl:text-2xl text-lightGray2 leading-[36px] mb-11 ">
          Get Paid, Communicate with Clients, and Manage <br /> Projects & Tasks
          - All on the same platform
        </p>
      </div>

      {/* animated circle with  */}
      <div className="w-full pb-[112px]">
        <div className="container">
          <div className="flex items-center ">
            <div className="flex basis-2/3 items-center">
              <div className="flex basis-1/2">
                <img src={files} alt="" />
              </div>
              <div className="basis-1/2 relative ">
                {/* the circles */}

                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[160px] h-[160px] rounded-full bg-gradient-to-tl from-[rgb(255,255,255,0.8)] to-white z-10 flex items-center justify-center ">
                  <img src={blueLogo} alt="" />
                </div>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[170px] h-[170px] rounded-full bg-gradient-to-tr from-primary to-accent  "></div>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[180px] h-[180px] rounded-full bg-gradient-to-tr from-primary to-accent opacity-[40%] "></div>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[190px] h-[190px] rounded-full bg-gradient-to-tr from-primary to-accent opacity-[25%] "></div>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[210px] h-[210px] rounded-full border-2 border-dashed border-lightGray2  "></div>

                {/* left arrow */}
                <div className="absolute -z-10 left-0 top-1/2 -translate-y-1/2">
                  <img src={lArrow} alt="" />
                </div>
                {/* right arrow */}
                <div className="absolute -z-10 right-0 top-1/2 -translate-y-1/2">
                  <img src={rArrow} alt="" />
                </div>
              </div>
            </div>

            <div className="flex flex-col basis-1/3 gap-4 ">
              <div className="flex flex-col gap-1 ">
                <h4 className="text-deepGray2 text-lg font-bold font-DM leading-[27px]">
                  Payment
                </h4>
                <p className="text-lightGray2 text-lg font-normal font-DM leading-[22px] ">
                  Bill your directly from <br /> inside the platform
                </p>
              </div>
              <div className="flex flex-col gap-1 ">
                <h4 className="text-deepGray2 text-lg font-bold font-DM leading-[27px]">
                  Project management
                </h4>
                <p className="text-lightGray2 text-lg font-normal font-DM leading-[22px] ">
                  Manage your departments & projects <br /> all in one system
                </p>
              </div>
              <div className="flex flex-col gap-1 ">
                <h4 className="text-deepGray2 text-lg font-bold font-DM leading-[27px]">
                  Communication
                </h4>
                <p className="text-lightGray2 text-lg font-normal font-DM leading-[22px] ">
                  Provate and group chats channels for clients
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Centralize al of your agency's operations */}
      <div className="w-full h-[1205px] rad-grad py-28">
        <div className="container mx-auto text-center">
          <h2 className="font-DM font-bold md:text-6xl xl:text-7xl leading-[72px] mb-11 text-white">
            {" "}
            Centralize all of your <br /> agency's{" "}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
              operations
            </span>{" "}
          </h2>
          <p className="font-DM md:text-xl xl:text-2xl text-white leading-[36px] mb-11 ">
            Get Paid, Communicate with Clients, and Manage <br /> Projects &
            Tasks - All on the same platform
          </p>
        </div>

        {/* bubbles and middle logo */}
        <div className="relative container w-[60%] h-[280px] mt-40">
          <img
            className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2"
            src={logocrest}
            alt="proflow.png"
          />
          <div className="absolute top-1/2 left-1/2 w-full h-[70vh] rad-grad-trans -translate-x-1/2 -translate-y-1/2 "></div>

          {/* floating bubbles */}
          {/* atom */}
          <div className="absolute -top-8 left-[20%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={atom} alt="proflow.png" />
          </div>
          {/* pulse */}
          <div className="absolute -top-4 left-[74%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={pulse} alt="proflow.png" />
          </div>
          {/* pointer */}
          <div className="absolute top-12 left-[90%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={pointer} alt="proflow.png" />
          </div>
          {/* bug */}
          <div className="absolute top-6 left-[6%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={bug} alt="proflow.png" />
          </div>
          {/* signal */}
          <div className="absolute top-24 -left-[8%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={signal} alt="proflow.png" />
          </div>
          {/* router */}
          <div className="absolute top-32 left-[104%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={router} alt="proflow.png" />
          </div>
          {/* batt */}
          <div className="absolute top-32 left-[80%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={batt} alt="proflow.png" />
          </div>
          {/* message */}
          <div className="absolute top-28 left-[16%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={message} alt="proflow.png" />
          </div>
          {/* battery */}
          <div className="absolute top-44 left-[4%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={battery} alt="proflow.png" />
          </div>
          {/* dollar */}
          <div className="absolute top-52 left-[90%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={dollar} alt="proflow.png" />
          </div>
          {/* tick */}
          <div className="absolute top-72 left-[72%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={tick} alt="proflow.png" />
          </div>
          {/* heart */}
          <div className="absolute top-64 left-[20%] w-[50px] h-[50px] flex items-center rounded-full justify-center shadow-lg shadow-shado bg-[#5D77EB]">
            <img src={heart} alt="proflow.png" />
          </div>
        </div>

        <div className="container">
          <div className="w-full text-center pt-12 flex flex-col gap-6">
            <Link to="/">
              <p className="font-DM font-bold z-20 text-2xl text-white">
                All-in-one solution
              </p>
            </Link>
            <Link to="/">
              <p className="font-DM font-bold z-20 text-2xl text-primary2">
                More clients
              </p>
            </Link>
            <Link to="/">
              <p className="font-DM font-bold z-20 text-2xl text-primary2">
                Increase retention rate
              </p>
            </Link>
            <Link to="/">
              <p className="font-DM font-bold z-20 text-2xl text-primary2">
                Spend less time in communicating
              </p>
            </Link>
            <Link to="/">
              <p className="font-DM font-bold z-20 text-2xl text-bluegray">
                Step up your game by using ProFlow
              </p>
            </Link>
          </div>
        </div>
      </div>

      {/* helping you to grow */}
      <div className="container py-32 flex items-center">
        <div className="basis-1/2">
          <h2 className="font-DM font-bold md:text-6xl xl:text-7xl leading-[72px] mb-11 text-deepGray">
            {" "}
            Helping you <br /> to{" "}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
              grow.
            </span>{" "}
          </h2>
          <p className="font-DM md:text-xl xl:text-2xl text-lightGray2 leading-[36px] mb-11 ">
            The results are just insane, we see our <br /> customers grow month
            after month by just <br /> using this tool!
          </p>
        </div>

        {/* labels on the right  */}
        <div className="flex flex-col basis-1/2 justify-between gap-6">
          <div className="md:w-[80%] xl:w-[90%] rounded-xl flex items-center justify-between ml-auto md:p-4 xl:p-8 bg-bluegray2">
            <div className="flex items-center gap-2">
              <div className="w-[60px] h-[60px] rounded-full flex items-center justify-between">
                <img src={head1} alt="proflow.com" />
              </div>
              <p className="md:text-xl xl:text-2xl font-DM text-white font-bold">
                Ronald Richards
              </p>
            </div>
            <img src={green} alt="proflow.com" />
          </div>
          <div className="md:w-[80%] xl:w-[90%] rounded-xl flex items-center justify-between ml-20 md:p-4 xl:p-8 bg-bluegray2">
            <div className="flex items-center gap-2">
              <div className="w-[60px] h-[60px] rounded-full flex items-center justify-between">
                <img src={head2} alt="proflow.com" />
              </div>
              <p className="md:text-xl xl:text-2xl font-DM text-white font-bold">
                Ronald Richards
              </p>
            </div>
            <img src={blue} alt="proflow.com" />
          </div>
          <div className="md:w-[80%] xl:w-[90%] rounded-xl flex items-center justify-between ml-auto md:p-4 xl:p-8 bg-bluegray2">
            <div className="flex items-center gap-2">
              <div className="w-[60px] h-[60px] rounded-full flex items-center justify-between">
                <img src={head3} alt="proflow.com" />
              </div>
              <p className="md:text-xl xl:text-2xl font-DM text-white font-bold">
                Ronald Richards
              </p>
            </div>
            <img src={yellow} alt="proflow.com" />
          </div>
        </div>
      </div>

      {/* this the section where "Different Services starts" */}

      <div className="container py-16 flex">
        <div className="basis-[64%] m-0 ">
          <img className="w-full" src={smallDash} alt="proflow.com" />
        </div>
        <div className="pt-20 basis-[36%] flex flex-col items-start gap-[176px]">
          {/* different services */}
          <div className="">
            <h2 className="font-DM font-bold md:text-6xl xl:text-7xl leading-[72px] mb-11 text-deepGray">
              Different <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
                services
              </span>
            </h2>
            <p className="font-DM md:text-xl xl:text-2xl text-lightGray2 leading-[36px] mb-11 ">
              We use the newest software to find <br /> your data. Used by many
              people <br />
              worldwide , we want to provide the perfect product for you.
            </p>
            <button className="font-DM font-bold text-lg text-white bg-gradient-to-r from-primary to-accent rounded-lg shadow-lg py-4 px-8">
              Get Started
            </button>
          </div>

          {/* Own Client Portal */}
          <div className="">
            <div className="w-[50px] h-[50px] flex items-center justify-center rounded-full mb-2 bg-gradient-to-r from-primary to-accent">
              <img src={home} alt="proflow.com" />
            </div>
            <h2 className="font-DM font-bold text-4xl leading-[72px] mb-2 text-deepGray">
              Own client portal.
            </h2>
            <p className="font-DM md:text-base xl:md:text-lg text-lightGray2 leading-[28px] mb-11 ">
              Build a branded client platform with <br /> customizable
              onboarding, private/group chats, <br />
              and dedicated client resource sections
            </p>
          </div>

          {/* Manage projects*/}
          <div className="">
            <div className="w-[50px] h-[50px] flex items-center justify-center rounded-full mb-2 bg-gradient-to-r from-primary to-accent">
              <img src={menu} alt="proflow.com" />
            </div>
            <h2 className="font-DM font-bold text-4xl leading-[72px] mb-2 text-deepGray">
              Manage projects
            </h2>
            <p className="font-DM md:text-base xl:md:text-lg text-lightGray2 leading-[28px] mb-11 ">
              Effortlessly manage all of your tasks and <br /> projects in one
              platform. Collaborate with <br /> clients by sharing projects and
              tasks
            </p>
          </div>

          {/* Invoices and Payments*/}
          <div className="">
            <div className="w-[50px] h-[50px] flex items-center justify-center rounded-full mb-2 bg-gradient-to-r from-primary to-accent">
              <img src={dollarz} alt="proflow.com" />
            </div>
            <h2 className="font-DM font-bold text-4xl leading-[72px] mb-2 text-deepGray">
              Invoice & Payments
            </h2>
            <p className="font-DM md:text-base xl:md:text-lg text-lightGray2 leading-[28px] mb-11 ">
              Use the platform to bill clients directly, with <br /> automated
              recurring billing for retainers.
            </p>
          </div>
        </div>
      </div>

      {/* We also care what others say */}
      <div className="w-full rad-grad pt-[130px] pb-[86px]">
        <div className="w-[90%] ml-auto flex items-center">
          <div className="basis-1/2">
            <h2 className="font-DM font-bold md:text-5xl xl:text-7xl bg-gradient-to-r bg-clip-text text-transparent  from-primary to-accent leading-[72px] mb-11 text-deepGray">
              <span className="bg-clip-text text-white ">We also </span>{" "}
              <span className="bg-clip-text text-transparent ">
                care <br /> what
              </span>{" "}
              <span className="bg-clip-text text-white ">others say</span>{" "}
            </h2>
            <p className=" font-DM md:text-lg xl:text-2xl text-lightondark">
              Since we collaborate with companies, it's crucial <br /> to
              receive their feedback and understand how <br /> we can
              continuously enhance our services to <br /> remain current.
            </p>
          </div>

          {/* The swiper side */}
          <div className="w-1/2">
            <div className=" w-full ">
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                autoplay={true}
                loop={true}
                speed={600}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                <SwiperSlide>
                  <div className=" md:w-[400px] md:h-[220px] xl:w-[540px] xl:h-[296px] bg-white rounded-2xl p-7 flex flex-col justify-between items-center">
                    {/* stars */}
                    <div className="flex gap-1  ">
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                    </div>
                    {/* up text */}
                    <div className="font-manrope text-center">
                      <h4 className="text-noteGrayh font-semibold md:text-xs xl:text-lg">
                        This is the best email finder
                      </h4>
                      <p className="text-noteGrayh font-normal md:text-[8px] xl:text-xs ">
                        This is the best tool I have come across so far. I say
                        this comparing with 6 email finders in almost two years.
                        Quick, precise & efficient results.
                      </p>
                    </div>

                    {/* name / date */}
                    <div className="font-manrope text-center">
                      {/* pic */}
                      <div className="flex justify-center">
                        <img src={testman} alt="proflow.com" />
                      </div>
                      <h4 className="text-noteGrayh font-semibold md:text-xs xl:text-lg">
                        Marvin McKinney
                      </h4>
                      <p className="text-noteGrayh font-normal  md:text-[8px] xl:text-xs">
                        04 - 02 - 2023
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className=" md:w-[400px] md:h-[220px] xl:w-[540px] xl:h-[296px] bg-white rounded-2xl p-7 flex flex-col justify-between items-center">
                    {/* stars */}
                    <div className="flex gap-1  ">
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                      <Icon
                        className="text-xl text-proyellow "
                        icon="ic:round-star"
                      />
                    </div>
                    {/* up text */}
                    <div className="font-manrope text-center">
                      <h4 className="text-noteGrayh font-semibold md:text-xs xl:text-lg">
                        This is the best email finder
                      </h4>
                      <p className="text-noteGrayh font-normal md:text-[8px] xl:text-xs ">
                        This is the best tool I have come across so far. I say
                        this comparing with 6 email finders in almost two years.
                        Quick, precise & efficient results.
                      </p>
                    </div>

                    {/* name / date */}
                    <div className="font-manrope text-center">
                      {/* pic */}
                      <div className="flex justify-center">
                        <img src={testman2} alt="proflow.com" />
                      </div>
                      <h4 className="text-noteGrayh font-semibold md:text-xs xl:text-lg">
                        Darlene Robertson
                      </h4>
                      <p className="text-noteGrayh font-normal  md:text-[8px] xl:text-xs">
                        03 - 02 - 2023
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                
              </Swiper>
            </div>
          </div>
        </div>
        {/* buttons */}
        <div className="container">
          <div className="w-1/2 ml-auto pt-2 flex justify-center gap-6">
            <div className="w-[44px] cursor-pointer h-[44px] rounded-full bg-white text-primary hover:bg-primary active:bg-accent hover:text-white flex items-center justify-center ">
              <ArrowLeft size="32" />
            </div>
            <div className="w-[44px] h-[44px] cursor-pointer rounded-full bg-white text-primary hover:bg-primary active:bg-accent hover:text-white flex items-center justify-center ">
              <ArrowRight size="32" />
            </div>
          </div>
        </div>
      </div>

      {/* Find your perfect plan */}
      <div className="container mx-auto text-center pt-[130px] pb-[10px] ">
        <h2 className="font-DM font-bold md:text-6xl xl:text-7xl leading-[72px] mb-11 text-deepGray">
          {" "}
          Find your perfect <br /> plan for{" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
            ProFlow
          </span>{" "}
        </h2>
        <p className="font-DM text-2xl text-lightGray2 leading-[36px] mb-11 ">
          ProFlow is cheaper than paying for multiple softwares separately.{" "}
          <br /> Take a look at all of the features you get when subscribing.
        </p>

        {/* PLANS AND PRICING */}

        <div className="flex w-full justify-between items-center py-44">
          {/* left pricing card */}
          <div className="w-[30%] h-fit rounded-3xl bg-white  shadow-lg p-9 flex flex-col items-center ">
            <div className="font-DM flex flex-col items-center">
              <h2 className="font-bold text-slightGray2 text-3xl mb-3 text-center ">
                Personal
              </h2>
              <p className="text-[22px] text-slightGray2 font-medium flex items-center gap-1 mb-7">
                {" "}
                <span className="text-slightGray">€</span> 12,99{" "}
                <span className="text-slightGray font-normal text-xs">
                  {" "}
                  / user
                </span>{" "}
              </p>
            </div>

            <hr className="w-full opacity-80" />

            <div className=" w-full flex flex-col items-start mt-10 gap-6">
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#5a5a5a" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  Create personal dashboard.
                </p>
              </div>
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#5a5a5a" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  Organixe your notes and
                  <span className="font-medium text-slightGray2">
                    workflows
                  </span>{" "}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#5a5a5a" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  <span className="font-medium text-slightGray2">5GB </span>
                  of space{" "}
                </p>
              </div>
              <div className="w-full flex justify-center mt-3 ">
                <button className="bg-gradient-to-r from-primary to-accent flex items-center text-base font-DM font-bold text-white p-3 rounded-2xl gap-2">
                  Choose this plan <ArrowRight size="22" color="#fff" />
                </button>
              </div>
            </div>
          </div>

          <div className="w-[30%] rounded-3xl bg-[#1e1e1e] shadow-lg p-9 flex flex-col items-center ">
            <div className="font-DM p-1 text-[22px]  text-white rounded-xl bg-gradient-to-r from-primary to-accent w-fit mb-4">
              {" "}
              -30%
            </div>
            <div className="font-DM flex flex-col items-center">
              <h2 className="font-bold text-white text-3xl mb-3">Pro Plan</h2>
              <p className="text-[22px] text-white font-medium flex items-center gap-1 mb-7">
                {" "}
                <span className="text-lightondark">€</span> 12,90{" "}
                <span className="text-lightondark font-normal text-xs">
                  {" "}
                  / user
                </span>{" "}
              </p>
            </div>

            <hr className="w-full opacity-40" />

            <div className=" w-full flex flex-col items-start mt-10 gap-6">
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#ffffff" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  All features in{" "}
                  <span className="font-medium text-white">Personal.</span>
                </p>
              </div>
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#ffffff" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  Unclock <span className="font-medium text-white">
                    Teams
                  </span>{" "}
                  to create a work group{" "}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#ffffff" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  <span className="font-medium text-white">20GB</span> of shared
                  space{" "}
                </p>
              </div>
              <div className="w-full flex justify-center mt-3">
                <button className="bg-gradient-to-r from-primary to-accent flex items-center text-base font-DM font-bold text-white p-3 rounded-2xl gap-2">
                  Choose this plan <ArrowRight size="22" color="#fff" />
                </button>
              </div>
            </div>
          </div>

          {/* right pricing card */}
          <div className="w-[30%] h-fit rounded-3xl bg-white  shadow-lg p-9 flex flex-col items-center ">
            <div className="font-DM flex flex-col items-center">
              <h2 className="font-bold text-slightGray2 text-3xl mb-3 text-center ">
                Enterprise
              </h2>
              <p className="text-[22px] text-slightGray2 font-medium flex items-center gap-1 mb-7">
                {" "}
                <span className="text-slightGray">€</span> 12,90{" "}
                <span className="text-slightGray font-normal text-xs">
                  {" "}
                  / user
                </span>{" "}
              </p>
            </div>

            <hr className="w-full opacity-80" />

            <div className=" w-full flex flex-col items-start mt-10 gap-6">
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#5a5a5a" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  All features in{" "}
                  <span className="font-medium text-slightGray2">
                    Pro Plan.
                  </span>
                </p>
              </div>
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#5a5a5a" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  Unclock{" "}
                  <span className="font-medium text-slightGray2">
                    Database
                  </span>{" "}
                  to manage your data{" "}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <TickCircle size="32" color="#5a5a5a" variant="Bold" />
                <p className="text-base text-slightGray ">
                  {" "}
                  <span className="font-medium text-slightGray2">500GB</span> /
                  5 shared spaces space{" "}
                </p>
              </div>
              <div className="w-full flex justify-center mt-3 ">
                <button className="bg-gradient-to-r from-primary to-accent flex items-center text-base font-DM font-bold text-white p-3 rounded-2xl gap-2">
                  Choose this plan <ArrowRight size="22" color="#fff" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer section */}
      <div className="w-full pt-9 pb-16  bg-footerGray ">
        {/* inner div  */}
        <div className="w-[70%] mx-auto">
          {/* top */}
          <div className="border-b flex gap-4 items-start justify-between pb-9 mb-9">
            <div className="flex flex-col w-[30%">
              {/* logo ========== */}
              <div className="flex items-center gap-4 mb-4">
                <img className="h-[43px] w-8 2xl:w-12 " src={logo} alt="" />
                <h3 className="font-bold text-2xl ">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-accent">
                    PRO
                  </span>
                  FLOW
                </h3>
              </div>
              {/* the intro text */}
              <p className=" font-manrope font-medium text-sm text-[#475467]">
                ProFlow is a powerful tool to combine every <br /> need of an
                agency in one
              </p>
              {/* social icons */}
              <div className="flex gap-3 items-center mt-16">
                <div className="w-[30px] h-[30px] rounded-full border border-slghtGray flex items-center justify-center">
                  <img src={fb} alt="" />
                </div>
                <div className="w-[30px] h-[30px] rounded-full border border-slghtGray flex items-center justify-center">
                  <img src={twt} alt="" />
                </div>
                <div className="w-[30px] h-[30px] rounded-full border border-slghtGray flex items-center justify-center">
                  <img src={linkedIn} alt="" />
                </div>
              </div>
            </div>
            <div class="w-[70%] font-DM overflow-x-auto ">
              <table class="table-auto w-full">
                <thead class="">
                  <tr class="">
                    <th class="text-left p-2 text-xl text[#101828] font-semibold">
                      About{" "}
                    </th>
                    <th class="text-left p-2 font-medium">Help & Support</th>
                    <th class="text-left p-2 font-medium">Resources</th>
                    <th class="text-left p-2 font-medium">Legal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="">
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      About Us
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Documentations
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      How to find emails?
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Privacy policy
                    </td>
                  </tr>
                  <tr class="">
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Pricing
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Frequently asked questions
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      How local search works
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Terms & Conditions
                    </td>
                  </tr>
                  <tr class="">
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Blog
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Contact us
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Integrate with Zapier
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Refund Policy
                    </td>
                  </tr>
                  <tr class="">
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Career with us
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Integrations
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      Integrate with Pipedrive
                    </td>
                    <td class=" font-manrope font-medium text-sm text-[#475467] p-2">
                      GDPR
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* bottom */}
          <div className="font-manrope text-lg text-[#475467] text-center">
            <p>All Rights Reserved by ProFlow 2023</p>
          </div>
        </div>
      </div>
      {/* end please put container here */}
    </div>
  );
}

export default Home;