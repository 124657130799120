import { Icon } from '@iconify/react';
import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/favicon.svg'

const Navbar = () => {
  return (
    <div className="w-full">
      <div className="container h-20">
        <div className="w-full flex items-center justify-between font-DM text-white py-5 ">
          {/* logo ========== */}
          <div className="flex items-center gap-4">
            <img className="h-[43px]" src={logo} alt="" />
            <h3 className="font-bold text-2xl  ">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent to-accent2">
                PRO
              </span>
              FLOW
            </h3>
          </div>

          {/* middle links ====== */}
          <ul className="flex items-center gap-10 text-sm text-pfgray ">
            <li className="hover:text-lfgray">
              <Link>Products</Link>
            </li>
            <li className="hover:text-lfgray">
              <Link>Solutions</Link>
            </li>
            <li className="hover:text-lfgray">
              <Link>Resources</Link>
            </li>
          </ul>
          <div className="flex items-center gap-8">
            <Link>
              {" "}
              <p className="font-inter font-medium text-sm text-pfgray hover:text-lfgray ">
                {" "}
                Login
              </p>{" "}
            </Link>
            <Link>
              <button className="flex items-center gap-2 text-sm font-semibold py-3 px-6 butt-grad rounded-full">
                {" "}
                Start Now <Icon
                  className="text-xl"
                  icon="mdi:chevron-right"
                />{" "}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 
 
export default Navbar